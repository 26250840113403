(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

require('./autoreg.js');

$(document).ready(function () {
    $('article').prependTo($('.main-text'));
    $('article h1').appendTo($('.for-h'));

    // Burger menu
    $('.burger').on('click', function () {
        $(this).toggleClass('burger_active');
        $('.header-navigation').slideToggle('fast');
    });
    $('.game-slider').slick({
        centerMode: true,
        centerPadding: '60px',
        slidesToShow: 4,
        arrows: true,
        responsive: [{
            breakpoint: 768,
            settings: {
                //   arrows: false,
                centerMode: true,
                centerPadding: '40px',
                slidesToShow: 2
            }
        }, {
            breakpoint: 480,
            settings: {
                //   arrows: false,
                centerMode: true,
                centerPadding: '40px',
                slidesToShow: 1
            }
        }]
    });

    // $('.game-slider').slick({
    //     autoplay: false,
    //     arrows: true,
    //     slidesToShow: 1,
    //     slidesToScroll: 1
    // });

    // lazy load
    // const images = document.querySelectorAll('.gamecard_img');

    // let arrImg = [];

    // images.forEach(img => {
    // const imgList = img.getAttribute('src');

    // img.setAttribute('data-src', imgList);

    // arrImg.push(imgList);

    // img.setAttribute('src', '');
    // });

    // const options = {
    //     root: null,
    //     rootMargin: '0px',
    //     threshold: 0.1
    // }

    // function handleImg(myImg, observer) {
    //     myImg.forEach(myImgSingle => {
    //         // console.log(myImgSingle.intersectionRatio);
    //         if (myImgSingle.intersectionRatio > 0) {
    //             loadImage(myImgSingle.target);
    //         }
    //     })
    // }

    // function loadImage(image) {
    //     image.src = image.getAttribute('data-src');
    // }

    // const observer = new IntersectionObserver(handleImg, options);

    // images.forEach(img => {
    //     observer.observe(img);
    // })
    // end lazy

    //random players
    function randomUser() {
        var playerNames = ['Rabbit Helpless', 'Warm Foal', 'Desire Kit', 'Angel Dusty', 'Sweety Frozen', 'Heavy Wombat', 'Lost Puma', 'Vital Panda', 'Rolling Sun', 'Steel Runny', 'Young Fox', 'Needlessr', 'Chipmunk Cult', 'Indigo Puppy', 'Dreaded Foal', 'GOT Kit', 'Angel Dusty', 'LOL Frozen', 'Silver Wombat', 'Lost Banan', 'Big Panda', 'Rolling Sun', 'Steel Runny', 'Young Fox', 'Needlessr', 'ZAZ NOV', 'FORD Puppy'];
        var namerand = Math.floor(Math.random() * playerNames.length);
        return playerNames[namerand];
    }
    $('.winners-block-items').each(function () {
        var randomNum = Math.floor(Math.random() * 10000 + 1000);
        randomNum = randomNum + ' UAH';
        $(this).find('.winner-name').append(randomUser());
        $(this).find('.winner-val').append(randomNum);
    });
    // $('.game-slot').each(function () {
    //     var randomNum = Math.floor(Math.random() * 30 + 113);
    //     $(this).find('.playing-now').append('Сейчас играют: ' + randomNum);
    // })

    $('.wrwr').slick({
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 1500,
        vertical: true
        // responsive: [
        //   {
        //     breakpoint: 1250,
        //     settings: {
        //       slidesToShow: 5
        //     }
        //   },
        //   {
        //     breakpoint: 420,
        //     settings: {
        //       slidesToShow: 2
        //     }
        //   }
        // ]
    });
    // GAME SLIDER INITIALISATION

    // $('.winners-block-items').slick({
    //     infinite: true,
    //     speed: 300,
    //     slidesToShow: 5,
    //     arrows: false,
    //     autoplay: true,
    //     autoplaySpeed: 1500,
    //     responsive: [{
    //             breakpoint: 1250,
    //             settings: {
    //                 slidesToShow: 4,
    //             }
    //         },
    //         {
    //             breakpoint: 800,
    //             settings: {
    //                 slidesToShow: 3,
    //             }
    //         },
    //         {
    //             breakpoint: 600,
    //             settings: {
    //                 slidesToShow: 2,
    //             }
    //         },
    //         {
    //             breakpoint: 420,
    //             settings: {
    //                 slidesToShow: 1,
    //             }
    //         }
    //     ]
    // });
});

},{"./autoreg.js":2}],2:[function(require,module,exports){
'use strict';

$('[data-link]').click(function () {
    var link = $(this).data('link');
    window.open('/' + atob(link), '_self');
});

},{}]},{},[1]);
